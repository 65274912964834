/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */

import { format } from 'date-fns'

// define a mixin object
export const dateRangePickerMixins = {
  data() {
    return {
      startEndDate: null,
      masks: {
        input: 'YYYY-MM-DD',
      },

      dateRangeFilter: false,
      dateRangeField: null,
    }
  },

  created() {

  },

  watch: {
    startEndDate(val) {
      const column = this.dateRangeField

      const startDate = format(val.start, 'yyyy-MM-dd')
      const endDate = format(val.end, 'yyyy-MM-dd')

      const value = { from: startDate, to: endDate }
      this.updateFilters(column, value)
      this.dateRangeFilter = false
    },
  },

  methods: {
    showDateRangeFilter(show, field) {
      this.dateRangeFilter = show
      this.dateRangeField = field
    },
    resetDateRangeFilter(show, field) {
      this.dateRangeField = field
      delete this.serverParams.columnFilters[field]
      this.startEndDate = null
      this.updateParams({})
    },
  },
}
