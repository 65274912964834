/* eslint-disable import/prefer-default-export */
export const completionMixins = {
  data() {
    return {
      popupProfileQuestions: false,
    }
  },
  created() {
    
  },
  methods: {
    checkForm(val) {
      // console.log('checkform ', val)
      //   this.popup_quiz = val
    },
    activateTypeForm(val) {
      // console.log('activatetype ', val)
      //   this.popupProfileQuestions = val
    },
    getQuestions(val) {
      // console.log('getquestions ', val)
      //   this.popup_quiz = true
      //   this.popup_questions = val.questions
      //   this.save_nric = val.save_nric
    },
    checkProfileCompletion() {
      let incomplete = true
      try {
        incomplete = this.$store.state.auth.ActiveUser.profile_status.incomplete
      } catch (error) {
        incomplete = true
      }
      
      if (this.$store.state.auth.ActiveUser.account_type !== this.$AccountTypes.APPLICANT) {
        incomplete = false
      }
      
      return incomplete
    },
  },
}
